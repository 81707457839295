<template>
	<div v-loading.fullscreen.lock="fullscreenLoading" class="pdf-images">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_1.jpg" @click="showImg(1)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_2.jpg" @click="showImg(2)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_3.jpg" @click="showImg(3)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_4.jpg" @click="showImg(4)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_5.jpg" @click="showImg(5)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_6.jpg" @click="showImg(6)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_7.jpg" @click="showImg(7)">
		<img src="../../../../assets/gnrhPdf/genderwoman/genderwoman_8.jpg" @click="showImg(8)">
	</div>
</template>

<script lang="js">
import { ImagePreview } from "vant";
import 'vant/lib/index.css';

export default {
	data() {
		return {
			fullscreenLoading: false,
			imgList: [
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_1.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_2.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_3.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_4.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_5.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_6.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_7.jpg'),
				require('../../../../assets/gnrhPdf/genderwoman/genderwoman_8.jpg')
			]
		};
	},
	mounted() {
		this.openFullScreen1();
	},
	methods: {
		openFullScreen1() {
			this.fullscreenLoading = true;
			setTimeout(() => {
				this.fullscreenLoading = false;
			}, 1000);
		},
		showImg(index) {
			ImagePreview({
				images: this.imgList,
				showIndex: true,
				loop: true,
				startPosition: index - 1
			});
		}
	}
};
</script>

<style scoped="scoped">
.pdf-images{
	width: 100%;
	float: left;
}
.pdf-images img{
	width: 100%;
	float: left;
}
</style>
